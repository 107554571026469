<template>
  <div class="calendar-page">
    <h1>Kurstermine</h1>
    <calendar-view
      :show-date="showDate"
      :startingDayOfWeek="1"
      displayWeekNumbers
      class="theme-default holiday-us-traditional holiday-us-official"
    >
      <template #header="{ headerProps }">
        <calendar-view-header
          :header-props="headerProps"
          @input="setShowDate"
        />
      </template>
    </calendar-view>
  </div>
</template>
<script>
import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar'

// https://github.com/richardtallent/vue-simple-calendar/tree/v5.0.0
// displayPeriodUom="" month, year, week
// displayPeriodCount

// v5.0.0
require("vue-simple-calendar/static/css/default.css")
require("vue-simple-calendar/static/css/holidays-us.css")

// v6.0.0
//import '../../node_modules/vue-simple-calendar/dist/style.css'
// The next two lines are optional themes
//import '../../node_modules/vue-simple-calendar/static/css/default.css'
//import '../../node_modules/vue-simple-calendar/static/css/holidays-us.css'

export default {
  name: 'app',
  components: {
    CalendarView,
    CalendarViewHeader,
  },
  data: function() {
    return { showDate: new Date() }
  },
  methods: {
    setShowDate(d) {
      this.showDate = d;
    },
  }
}
</script>
<style>
  .calendar-page {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    color: #2c3e50;
    height: 67vh;
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
  }
</style>
